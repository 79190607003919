import React from 'react';
import { EventsContainer, EventsSubcontainer, Subtitle, EventList, AccessButton } from '../UserHome.styled';
import { EventsLoader } from './EventsLoader';
import { EventItem } from './EventItem';
import { getSportIcon, getSportIconColor } from "../../../utils/events";

// New reusable component
const EventsSubsection = ({ title, events, t }) => (
  <EventsSubcontainer>
    <Subtitle>{title}</Subtitle>
    <EventList>
      {events.length === 0 ? (
        <EventsLoader />
      ) : (
        events.map(([sport, sportEvents]) => (
          <EventItem
            key={sport}
            sport={sport}
            events={sportEvents}
            icon={getSportIcon(sport)}
            iconColor={getSportIconColor(sport)}
            t={t}
          />
        ))
      )}
    </EventList>
  </EventsSubcontainer>
);

export const EventsSection = ({ events, upcomingEvents, t }) => {
  const getLoadedEvents = () => Object.entries(events);
  
  const getUpcomingEventsGrouppedBySport = () =>
    Object.values(upcomingEvents).flatMap(x => x).reduce((t, c) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const eventDate = new Date(c.startedAt);
      eventDate.setHours(0, 0, 0, 0);
      if (today.getTime() === eventDate.getTime()) {
        const sport = c.sport.name;
        if (t[sport]) {
          t[sport].push(c);
        } else {
          t[sport] = [c];
        }
      }
      return t;
    }, {})

  return (
    <>
      <EventsContainer>
        <EventsSubsection 
          title={t('liveEventsNow')}
          events={getLoadedEvents()}
          t={t}
        />
        <AccessButton className="btn btn-lg" href="/arbs/live">
          {t('accessSurebets', { type: 'Live' })} &#8594;
        </AccessButton>
      </EventsContainer>
      <EventsContainer>
        <EventsSubsection 
          title={t('scheduledEvents')}
          events={Object.entries(getUpcomingEventsGrouppedBySport())}
          t={t}
        />
        <AccessButton className="btn btn-lg" href="/events">
          {t('events')} &#8594;
        </AccessButton>
      </EventsContainer>
    </>
  );
};