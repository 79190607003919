import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #101c11;
  gap: 30px;
  padding: 40px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    height: 100vh;
    overflow: hidden;
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
`;

export const GraphicSide = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a2a2a2;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 60px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    height: 25vh;
    order: -1;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const Logo = styled.img`
  width: 60px;
  height: auto;
`;

export const FormSide = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 40px;
  background: #111E12;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #E5E5E5;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 70vh;
    padding: 20px;
    order: 1;
    justify-content: center;
    overflow-y: visible;
  }
`;

export const WelcomeContainer = styled.div`
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const RegisterTitle = styled.h2`
  color: #E5E5E5;
  font-family: Inter;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
  font-size: 32px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 4px;
  }
`;

export const JourneyText = styled.p`
  color: #a2a2a2;
  margin: 0;
  font-size: 16px;
`;

export const LoginForm = styled.form`
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InputGroup = styled.div`
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  height: 50px;
  color: #111E12;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #E5E5E5;
  color: #111E12;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  height: 60px;

  :hover {
    background: #E9FD8C;
  }
`;

export const RegisterLink = styled.span`
  text-align: center;
  margin-top: 16px;
  color: #a2a2a2;
  font-size: 14px;

  a {
    color: white;
    font-weight: 500;
    text-decoration: none;
    margin-left: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: none;
  color: #E5E5E5;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  transition: background 0.2s;
  z-index: 10;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;