import styled from "styled-components";
import { Button } from "react-bootstrap";

export const UserBetsContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;

  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    padding: 10px;
  }

  @media only screen and (max-width: 800px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }
`

export const SelectedEvent = styled.div`
  margin: 20px 0px;
  font-size: medium;
`

export const UserProfit = styled.div`
  margin-right: 10px;

  @media only screen and (max-width: 800px) {
    position: unset;
    right: 0;
    margin-right: 2px;
  }
`

export const StyledBadge = styled.a`
  margin-left: 5px; 
  line-height: 2
`

export const Container = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

export const AllArbsContainer = styled.div`
  width:  ${props => props.pinnedArb ? '50%' : '100%'};

  @media only screen and (max-width: 800px) {
    width:  100%;
    order: 2;
  }
`
export const SelectedArbContainer = styled.div`
  width:  50%;

  @media only screen and (max-width: 800px) {
    width:  100%;
    order: 1;
  }
`
export const TopBar = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  background: #192419;
  padding: 10px;
  border-radius: 5px;
  color: white;
  width: 100%;
  justify-content: space-between;
`

export const LeftBar = styled.div`
  display: flex;
  align-items: center;
`

export const RightBar = styled.div`
  display: flex;
  align-items: center;
`

export const ConfigureButton = styled(Button)`
  background-color: #1d2d1d;
  border: unset;
  font-weight: bold;

  :disabled {
    background-color: #1d2d1d;
    color: white;
  }

  :hover {
    background-color: #E9FD8C;
    color: #1d2d1d;
  }

  :active {
    background-color: #E9FD8C !important;
    color: #1d2d1d !important;
  }

  :focus {
    background-color: #E9FD8C;
    color: #1d2d1d;
  }
`

export const EventsContainer = styled.div`
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #111e12;
  border-radius: 5px;
  padding: 5px;
  display: flex;

  :hover {
    cursor: pointer;
    border: 2px solid #b8dcef;
  }
`

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: ${props => props.filter};
`

export const ConnectionBadge = styled.div`
  margin-left: 5px;
  background-color: ${props => props.connected ? 'rgb(5, 45, 35)' : '#ff6b66'};
  border: ${props => props.connected ? '2px solid #111e12' : '2px solid #522121'};
  color: ${props => props.connected ? 'rgb(218, 255, 153)' : 'white'};
  border-radius: 5px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 750px) {
    display: none;
  }
`

export const VisitorBadge = styled.div`
  margin-left: 10px;
  background-color: 'rgba(91, 91, 91, 0.1)';
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
`

export const ReportButton = styled.button`
  margin-left: 5px;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  border: 2px solid #111e12;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 7px;

  :hover {
    background: rgba(0, 0, 0, 0.4);
    color: #e9fd8c;
  }

  a {
    margin-left: 3px;
  }

  .fa-triangle-exclamation {
    color: #e9fd8c;
    margin-right: 5px;
  }

  @media (max-width: 750px) {
    display: none;
  }
`