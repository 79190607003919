import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { NavBarRegister } from '..'
import {
    LoginContainer, GraphicSide, FormSide, LoginForm, InputGroup, Input, Button,
    BackgroundImage, LogoContainer, RegisterTitle, Logo, WelcomeContainer, JourneyText,
    RegisterLink, BackButton
} from "./Login.styled";
import { useAuth } from "../../hooks";
import { checkToken } from "../../repositories/utils";
import tennisCourt from "../../static/tennis_court.webp";
import LogoImage from "../../static/logo_white.svg";

export const Login = (props) => {
    const [email, setEmail] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { login } = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        if (checkToken()) props.history.push('/home/logged');
    })

    const handleEmailChange = event => setEmail(event.target.value);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        login(email);
    };
  
    return(
        <>
            <LoginContainer>
                <FormSide>
                    <BackButton onClick={() => props.history.push('/')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                        </svg>
                        {t('back')}
                    </BackButton>
                    {!isSubmitted ? (
                        <>
                            <LogoContainer>
                                <Logo src={LogoImage} alt="Company Logo" />
                            </LogoContainer>
                            <WelcomeContainer>
                                <RegisterTitle>{t('welcomeBack')}</RegisterTitle>
                                <JourneyText>{t('journeyMessage')}</JourneyText>
                            </WelcomeContainer>
                            <LoginForm onSubmit={handleSubmit}>
                                <InputGroup>
                                    <Input 
                                        type="email" 
                                        placeholder="Email" 
                                        required 
                                        onChange={handleEmailChange} 
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Button type="submit">{t('login')}</Button>
                                </InputGroup>
                                <RegisterLink>
                                    {t('noAccount')}<Link to="/register">{t('signupHere')}</Link>
                                </RegisterLink>
                            </LoginForm>
                        </>
                    ) : (
                        <WelcomeContainer>
                            <RegisterTitle>
                                <Trans>
                                    {t('checkLine1')}
                                </Trans>
                            </RegisterTitle>
                            <JourneyText>
                                <Trans>
                                    {t('checkLine2')}
                                </Trans>
                            </JourneyText>
                        </WelcomeContainer>
                    )}
                </FormSide>
                <GraphicSide>
                    <BackgroundImage src={tennisCourt} alt="mountain landscape" />
                </GraphicSide>
            </LoginContainer>
        </>
    )
};
