import React from "react";
import "./ArbEventCard.styled";
import {
  getSportIcon,
  getGoalIcon,
  getSportIconColor,
  getStatusInformation,
  getScoreForArbs,
  getSubScoreForArbs,
  checkIfScoreChanged,
  getBetStatusInformation
} from "../../utils/events";
import {
  BetCardContainer,
  Match,
  FlexContainer,
  MatchOverview,
  SportIcon,
  EventNameText,
  PlacedBetsContainer,
  StatusBadge,
  LeagueNameText,
  ExtraArbsBadge,
  ScoreBadge,
  SubScoreBadge,
  StartTimeBadge,
  GoalIconContainer,
  GoalIcon,
  DivisionContainer,
  EventTextContainer,
  StatusContainer,
  CountryNameText
} from "./ArbEventCard.styled";
import { ArbCard } from "..";

const filterUniqueBookies = arbs => {
  const seenBookiePairs = new Set();
  return arbs.filter(arb => {
    const bookiePair = arb.bets
      .map(bet => bet.bookie)
      .sort()
      .join('-');
    
    if (!seenBookiePairs.has(bookiePair)) {
      seenBookiePairs.add(bookiePair);
      return true;
    }
    return false;
  });
};

const fillUpToMax = (initialArbs, remainingArbs, maxArbs) => {
  if (initialArbs.length >= maxArbs) {
    return initialArbs.slice(0, maxArbs);
  }

  const initialArbIds = new Set(initialArbs.map(arb => arb.id));
  const additionalArbs = filterUniqueBookies(remainingArbs)
    .filter(arb => !initialArbIds.has(arb.id))
    .slice(0, maxArbs - initialArbs.length);

  return [...initialArbs, ...additionalArbs];
};

const getFirstShownArbs = ({ arbs, configuration }) => {
  const maxNumberOfArbs = configuration?.maxNumberOfArbs || 2;

  if (['timeAsc'].includes(configuration?.sortBy)) {
    const currentTimestamp = new Date().getTime();
    const arbsUpdatedOnTheLastSeconds = arbs.filter(arb => currentTimestamp - arb.version < 5000);
    const uniqueArbs = filterUniqueBookies(arbsUpdatedOnTheLastSeconds)
      .sort((a, b) => a.rawProfit - b.rawProfit);
    
    return fillUpToMax(uniqueArbs, arbs, maxNumberOfArbs);
  } else if (['timeDes'].includes(configuration?.sortBy)) {
    const firstArbTimestamp = arbs[0].version;
    const arbsUpdatedOnTheLastSeconds = arbs.filter(arb => Math.abs(firstArbTimestamp - arb.version) < 3000);
    const uniqueArbs = filterUniqueBookies(arbsUpdatedOnTheLastSeconds)
      .sort((a, b) => a.rawProfit - b.rawProfit);
    
    return fillUpToMax(uniqueArbs, arbs, maxNumberOfArbs);
  } else {
    return filterUniqueBookies(arbs).slice(0, maxNumberOfArbs);
  }
};

const getAdditionalStatusArbs = (shownArbs, allArbs, configuration) => {
  const maxArbs = configuration?.maxNumberOfArbsWithStatus || 2;

  const shownArbIds = new Set(shownArbs.map(arb => arb.id));
  const seenBookiePairs = new Set(
    shownArbs.map(arb => 
      arb.bets
        .map(bet => bet.bookie)
        .sort()
        .join('-')
    )
  );

  return allArbs
    .filter(arb => 
      !shownArbIds.has(arb.id) && 
      getBetStatusInformation(arb) &&
      !seenBookiePairs.has(
        arb.bets
          .map(bet => bet.bookie)
          .sort()
          .join('-')
      )
    )
    .slice(0, maxArbs);
};

const getDisplayableArbs = ({ arbs, eventFiltered, configuration }) => {
  if (eventFiltered) return arbs;
  if (arbs.length === 0) return [];

  const firstShownArbs = getFirstShownArbs({ arbs, configuration });

  if (!configuration?.showNextPeriodExtraBet) return firstShownArbs;

  const additionalStatusArbs = getAdditionalStatusArbs(firstShownArbs, arbs, configuration);
  
  return [...firstShownArbs, ...additionalStatusArbs];
};

export const ArbEventCard = ({ arbs = [], event, setEvent, setPinnedArb, captureSnapshot, eventFiltered, pre = false, configuration }) => {
  return (
    <BetCardContainer
      className="card"
      data-sport-name={event.sport?.name}
      onClick={() => setEvent(event)}
      status={event?.status}
      arbStatus={!eventFiltered ? arbs[0]?.status : undefined}
    >
      <MatchOverview>
        <Match>
          <SportIcon src={getSportIcon(event.sport?.name)} filter={getSportIconColor(event.sport?.name)} />
          <EventTextContainer>
            <EventNameText className="event" data-event-name={event?.name}> {event?.name} </EventNameText>
            <DivisionContainer>
              {event?.league?.country && <CountryNameText className="country" data-country-name={event?.league?.country}>{event?.league?.country}</CountryNameText>}
              <LeagueNameText className="league" data-league-name={event?.league?.name}>{event?.league?.name}</LeagueNameText>
            </DivisionContainer>
            {
              <StatusContainer>
                {
                  !pre && <>
                    <ScoreBadge className="badge badge-secondary">{getScoreForArbs(event)} </ScoreBadge>
                    {getSubScoreForArbs(event) && (
                      <SubScoreBadge className="badge badge-secondary">{getSubScoreForArbs(event)}</SubScoreBadge>
                    )}
                    {checkIfScoreChanged(event) &&
                      <GoalIconContainer className="badge badge-secondary">
                        <GoalIcon src={getGoalIcon(event.sport?.name)} />
                        <b>GOAL</b>
                      </GoalIconContainer>
                    }
                    {getStatusInformation(event) && (
                      <StatusBadge
                        className="badge"
                        status={event?.status}
                        arbStatus={!eventFiltered ? arbs[0]?.status : undefined}
                      >
                        {" "}
                        {getStatusInformation(event)}{" "}
                      </StatusBadge>
                    )}
                  </>
                }
                {arbs.length > 1 && (
                  <ExtraArbsBadge className="badge badge-secondary">
                    {" "}
                    +{arbs.length - 1} {arbs.length - 1 > 1 ? "Arbs" : "Arb"}{" "}
                  </ExtraArbsBadge>
                )}
              </StatusContainer>
            }
          </EventTextContainer>
        </Match>
      </MatchOverview>
      { pre && !eventFiltered && 
      <MatchOverview>
        <FlexContainer>
          { event.startedAt && <StartTimeBadge className="badge">{new Date(event.startedAt).toLocaleString()}</StartTimeBadge> }
        </FlexContainer>
      </MatchOverview> 
      }
      <PlacedBetsContainer>
        {getDisplayableArbs({ arbs, eventFiltered, configuration }).map((arb) => (
          <ArbCard
            key={arb.id}
            arb={arb}
            captureSnapshot={captureSnapshot}
            event={event}
            setEvent={setEvent}
            setPinnedArb={setPinnedArb}
            pre={pre}
          />
        ))}
      </PlacedBetsContainer>
    </BetCardContainer>
  );
};
