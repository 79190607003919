import styled, {css} from "styled-components";
import Badge from "react-bootstrap/Badge";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const ConfigureModal = styled(Modal)`
  .modal-content {
    font-family: 'Montserrat';
    background-color: #111E12;
    color: white;

  }

  .modal-dialog {
    max-width: 900px;
    width: 100%;
  }

  .modal-header {
    background-color: #111E12;
    color: white;
  }

  .close {
    color: white;
  }

  label {
    font-weight: bold;
  }

  .dropdown-toggle:hover,
  .dropdown-toggle:active {
    background-color: #E9FD8C;
    border-color: #1d2d1d;
    color: #1d2d1d;
  }

  .show>.btn-secondary.dropdown-toggle,
  .btn-secondary:focus,
  .btn-secondary:hover,
  .btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #E9FD8C;
    border-color: #1d2d1d;
    color: #1d2d1d;
  }

  .dropdown-menu {
    border: unset;
  }

  .dropdown-menu > .active {
    background-color: #E9FD8C;
    color: #1d2d1d;
  }

  .dropdown-item.delete:hover {
    background-color: #ff0000;
    color: white;
  }

  .form-label {
    font-size: 1.1rem;
    font-weight: normal;
  }
`;

export const SeparatorWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #1d2d1d;

  .form-label {
    margin: 0 10px;
    color: white;
    font-size: 1.1rem;
  }

  hr {
    flex: 1;
    background-color: rgb(68, 68, 68);
    height: 1px;
    border: none;
  }
`;

export const Fieldset = styled.fieldset`
  ${({hide}) => hide && css`display: none;`};
  ${({disabled}) => disabled && css`
    color: gray;
    & input,select,label.badge {
      cursor: not-allowed;
    }
    `
  };
`;

export const BookieBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  flex-grow: 1;
  width: 100%;

  border: 1px solid #1d2d1d;
  background-color: ${(props) => props.checked ? (props.isReference ? "#e9fd8c" : "#B8DCEF") : "rgb(254, 254, 244)"};
  color: #1d2d1d;

  ${({hide}) => hide && css`display: none;`};
  ${({clickable}) => clickable !== false && css`
    cursor: pointer;
    input[type=checkbox] {
      cursor: pointer;
    }

    :hover {
      border: 1px solid #007053;
      color: #007053;
      cursor: pointer;
    }
    `
  };
  
  & button.close {
    top: 4px;
    right: 5px;
    margin-left: 5px;
    height: 14px;
    line-height: 14px;
  }
`;

export const WideBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  border-radius: 7px;
  
  border: ${(props) => !props.checked ? "3px solid #1d2d1d" : "3px solid #1d2d1d"};
  background-color: ${(props) => props.checked ? (props.isReference ? "#350F87" : "#e9fd8c") : "rgb(254, 254, 244)"};
  color: ${(props) => !props.checked ? "#1d2d1d" : "#1d2d1d"};

  ${({hide}) => hide && css`display: none;`};
  ${({clickable}) => clickable !== false && css`
    cursor: pointer;
    input[type=checkbox] {
      cursor: pointer;
    }

    :hover {
      border: 3px solid #007053;
      color: #007053;
    }
    `
  };
  
  & button.close {
    top: 4px;
    right: 5px;
    margin-left: 5px;
    height: 14px;
    line-height: 14px;
  }
`;

export const LabelSelectAll = styled.label`
  font-size: 100%;
  background-color: rgb(68, 68, 68);
  padding: 0.6em;
  border-radius: 7px;
  line-height: 1em;
  user-select: none;
  cursor: pointer;
  background: ${(props) => props.checked ? "#B8DCEF" : "#1d2d1d"};
  border: 3px solid #111E12;
  color: ${(props) => props.checked ? "#1d2d1d" : "white"};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0px;

  :hover {
    background: #B8DCEF;
    color: #1d2d1d;
    font-weight: bold;
  }

  input[type=checkbox] {
    cursor: pointer;
  }
`;

export const LabelShowClones = styled.label`
  font-size: 100%;
  margin: 0px 10px;
  background-color: rgb(68, 68, 68);
  padding: 0.6em;
  border-radius: 7px;
  line-height: 1em;
  user-select: none;
  cursor: pointer;
  background: ${(props) => props.checked ? "#e9fd8c" : "#1d2d1d"};
  color: ${(props) => props.checked ? "#1d2d1d" : "white"};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  :hover {
    background: #e9fd8c;
    color: #1d2d1d;
    font-weight: bold;
  }

  input[type=checkbox] {
    cursor: pointer;
  }
`;

export const DropdownButtonAppended = styled(DropdownButton)`
  > button.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const BookiesContainer = styled.div`
  background: rgb(29,45,29);
  padding: 10px;
  border-radius: 5px;
  color: white;

`;

export const LabelsContainer = styled.div`
  background: #1d2d1d;
  padding: 10px;
  border-radius: 5px;
  color: white;
  margin-bottom: 10px;
`;

export const SelectionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 5px;
  }
`

export const CountriesSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #111E12;
  padding: 10px;
  border-radius: 5px;
  height: 55px;
`

export const BookieSearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #111E12;
  padding: 10px;
  border-radius: 5px;
  width: 240px;
  height: 55px;
  margin: 15px 0px;
`

export const BookieSearchInputContainer = styled.div`
  width: 200px;

  input {
    background-color: #1d2d1d;
    border: 0px;
    color: white;

      ::placeholder {
          color: white;
          opacity: 0.6;
      }
  }
`

export const BookieListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 5px;
`;

export const ClonesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #82a880;
  // padding: 5px 0px 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  flex-grow: 1;
`;

export const MainBookieContainer = styled.div`
  background: #3d593c;
  padding: 10px 9px 0px 10px;
  border-radius: 5px;
  flex-grow: 1;

  ${props => props.nonClone && `margin-bottom: 5px;`}

`;

export const Clone2Container = styled.div`
  display: flex;
  gap: 5px;

  padding: 10px 9px 0px 10px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

export const CopyFromButton = styled(Button)`
  background-color: #E9FD8C;
  border-color: #1d2d1d;
  color: #1d2d1d;
  font-weight: bold;

  :hover {
    color: #007053;
    background-color: #E9FD8C;
    border-color: #007053;
  }
`;