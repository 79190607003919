import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import {
  StyledNavbar,
  Nav,
  NavBarLink,
  Header,
  Logo,
  NavDrop,
  NavLinkSignup,
  NavLinkLogin,
  NavRightContainer,
  NavMobile,
  NavNotMobile,
  NavDropUser,
  NavDropLanguage,
} from "./NavBarMain.styled";
import logoWithName from "../../static/logoWithName.png";
import logoWithNameInverted from "../../static/logoWithNameInverted.png";
import { useUsers } from "../../contexts/UsersContext";
import Navbar from 'react-bootstrap/Navbar';
import { checkIfTokenStillValid, logout } from "../../repositories/utils";

const useTokenValidation = () => {
  const [validToken, setValidToken] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const token = await checkIfTokenStillValid();
      setValidToken(token);
    };
    validateToken();
  }, []);

  return validToken;
};

const LanguageDropdown = React.memo(() => {
  const { t } = useTranslation();
  return (
    <NavDropLanguage title={i18n.language === 'en' ? '🇬🇧' : '🇪🇸'} id="language-dropdown">
      <NavDropLanguage.Item onClick={() => i18n.changeLanguage('en')}>
        🇬🇧 {t('english')}
      </NavDropLanguage.Item>
      <NavDropLanguage.Item onClick={() => i18n.changeLanguage('es')}>
        🇪🇸 {t('spanish')}
      </NavDropLanguage.Item>
    </NavDropLanguage>
  );
});

LanguageDropdown.displayName = 'LanguageDropdown';

export const NavBarMain = ({ currentPage, type, inverted = false, history }) => {
  const { t } = useTranslation();
  const { users } = useUsers();
  const validToken = useTokenValidation();

  const handleLogout = useCallback(() => logout(history), [history]);

  const navLinks = useMemo(() => (
    <>
      {currentPage !== "globalHome" && <NavBarLink inverted={inverted} active={currentPage === "home"} className="btn btn-lg" href="/home/logged"><i className="fas fa-home" /> {t('home')}</NavBarLink>}
      <NavBarLink inverted={inverted} active={currentPage === "arbs"} className="btn btn-lg" href="/arbs/live"><i className="fas fa-play" />  Live</NavBarLink>
      {/* <NavDrop inverted={inverted} currentPage={currentPage} active={currentPage === "pre"} title={<><i className="fas fa-clock"/> Prematch</>} id="basic-nav-dropdown">
        <NavDrop.Item inverted={inverted} active={type === 'arbsPre'} href="/arbs/pre">Surebets</NavDrop.Item>
        <NavDrop.Item inverted={inverted} active={type === 'valuebetsPre'} href="/valuebets">Valuebets</NavDrop.Item>
      </NavDrop> */}
      <NavBarLink inverted={inverted} active={currentPage === "events"} className="btn btn-lg" href="/events"><i className="fas fa-calendar" /> {t('events')}</NavBarLink>
      <NavBarLink inverted={inverted} active={currentPage === "extension"} className="btn btn-lg" href="/extension"><i className="fa-brands fa-chrome" />  Extension</NavBarLink>
      <NavBarLink inverted={inverted} active={currentPage === "rewards"} className="btn btn-lg" href="/rewards"><i className="fas fa-trophy" />  {t('rewards')}</NavBarLink>
    </>
  ), [currentPage, type, inverted, t]);

  const userDropdown = useMemo(() => (
    <NavDropUser align="left" title={<i className="fas fa-user" />} className="form-inline my-2 my-lg-0" >
      <NavDrop.Item inverted={inverted} href="/invoices">
        {t('invoices')}
      </NavDrop.Item>
      <NavDrop.Divider />
      <NavDrop.Item inverted={inverted} onClick={handleLogout} style={{ color: '#1d2d1d' }}>
        Logout
      </NavDrop.Item>
    </NavDropUser>
  ), [inverted, t, handleLogout]);

  return (
    <StyledNavbar currentPage={currentPage} expand="lg">
      <Header>
        <Logo>
          <Link to="/home">
            <img src={inverted ? logoWithNameInverted : logoWithName} alt="BetSmarter Logo" />
          </Link>
          <LanguageDropdown />
        </Logo>
        <NavRightContainer>
          {currentPage === "globalHome" && (
            <NavMobile style={{ gap: '10px' }}>
              <NavLinkLogin className="btn btn-lg" href={validToken ? "/home/logged" : "/login"}>
                {validToken ? t('home') : t('login')}
              </NavLinkLogin>
            </NavMobile>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </NavRightContainer>
      </Header>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {validToken && navLinks}
          {!validToken && <NavBarLink inverted={inverted} active={currentPage === "pricing"} className="btn btn-lg" href="/pricing"><i className="fas fa-money-bill" /> {t('pricing')}</NavBarLink>}
          <NavBarLink inverted={inverted} active={currentPage === "academy"} className="btn btn-lg" target="_blank" href="https://academia.betsmarter.app/"><i className="fas fa-graduation-cap" /> Academy</NavBarLink>
          {validToken && users && users.length > 0 && 
          <NavDrop inverted={inverted} currentPage={currentPage} active={currentPage === "bot"} title={<><i className="fas fa-robot" /> Bot</>} id="basic-nav-dropdown">
            <NavDrop.Item inverted={inverted} active={type === 'dashboard'} href="/home/bot">Panel</NavDrop.Item>
            <NavDrop.Item inverted={inverted} active={type === 'stats'} href="/home/bot/stats">Stats</NavDrop.Item>
          </NavDrop>
          }

        </Nav>
        {validToken && userDropdown}
      </Navbar.Collapse>
      {currentPage === "globalHome" && (
        <NavNotMobile style={{ gap: '10px' }}>
          {validToken ? (
            <NavLinkLogin className="btn btn-lg" href="/home/logged">{t('home')}</NavLinkLogin>
          ) : (
            <>
              <NavLinkSignup className="btn btn-lg" href="/register" style={{ width: '140px' }}>{t('signup')}</NavLinkSignup>
              <NavLinkLogin className="btn btn-lg" href="/login" style={{ width: '140px' }}>{t('login')}</NavLinkLogin>
            </>
          )}
        </NavNotMobile>
      )}
    </StyledNavbar>
  );
};